.goals-summary-container {
  .goals-summary-cta {
    text-align: center;
    margin: 16rem auto 16rem auto;
    width: 489px;

    h2 {
      font-size: 24px;
      width: 489px;
      margin: 0 auto 15px;
    }
  }
  .goals-summary-popover {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 8px;
  }

  .goal-list {
    padding: 48px 48px 24px 48px;
    .accordion-tables {
      li {
        list-style: none;
      }
      padding-top: 8px;
      .ReactTable.table {
        border-color: transparent;
        font-size: 15px;

        .rt-thead .rt-tr {
          height: 36px !important;
        }

        .rt-td {
          border-color: transparent;
        }

        .rt-td:focus,
        .rt-td:hover {
          background-color: transparent !important;
        }

        .rt-th {
          border-color: #eaeaea !important;
        }

        .rt-th,
        .rt-resizable-header-content {
          font-family: RobotoCondensed, Roboto, sans-serif;
          color: #3d4042;
          font-size: 11px;
          font-weight: 400;
          font-stretch: condensed;
          text-transform: uppercase;
        }

        .rt-expandable .icon {
          position: relative;
          left: 4px;
        }

        .disabled-column {
          color: $color-nt-grey-400;
        }

        .table-label-13px,
        .table-label-15px,
        .table-sublabel-12px {
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .table-label-13px {
          font-size: 13px;
          line-height: 18px;
        }

        .table-label-15px {
          font-size: 15px;
          line-height: 20px;
        }

        .table-sublabel-12px {
          font-size: 12px;
          line-height: 15px;
          color: $color-nt-grey-550;
        }
      }

      .underlined-header {
        padding-left: 24px;
        padding-right: 36px;
        border: none;
        margin-bottom: 0 !important;
      }

      .value-label {
        width: 190px;
        text-align: right;
      }
    }

    .page-action-menu-options {
      display: flex;
      justify-content: space-between;
    }

    input[name="discount-group"] {
      accent-color: #3D4042;
    }

    input[name="show-lifestyle-spending"] {
      accent-color: #3D4042;
    }

    .goal-list-header-grid {
      display: grid;
      grid-template-columns: 50% 43% 32px;
    }
  }

  .accordion-header-content {
    height: 64px;
    background-color: #fff;
    align-items: center;
    border-radius: 5px;
  
    & > * {
      padding-left: 8px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.hover-text {
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #3D4042;
  font-style: normal;
  line-height: 18px;
}

.add-goal-dropdown-item, .add-goal-dropdown-item:hover {
  text-align: left;
  width: auto;
  min-width: 210px;
  text-decoration: none;
  color: #3D4042
}

.add-family-goal-dropdown-menu .btn--medium {
  width: 227px !important;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.goal-type-button {
  width: 227px !important;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.family-goals-accordion-header-grid {
  display: grid;
  grid-template-columns: minmax(200px, 200px) repeat(2, minmax(10%, 0.75fr)) repeat(3, minmax(10%, 1fr)) 40px;
}

.family-goal-table {
    [role="cell"] {
      position: relative;
      padding-left: 8px;
      padding-right: 8px;

      .cell-with-popover {
        display: flex;
        z-index: 1;
      }

      .cell-text-content {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 15px;
      }
    }
}

.beneficiary-age {
    white-space: nowrap;
}

.goals-table-goal-name {
    font-size: 15px;
}
