@import "../abstracts/colors";

.create-report {
  .header {
    padding-bottom: 0;
  }

  .tab-bar {
    margin-top: 16px;
  }

  .tab__item--active,
  .tab__item-skin:focus,
  .tab__item-skin a:focus {
    border-bottom: 4px solid white;
  }

  .tab__item-skin.active a:hover,
  .tab__item-skin:hover,
  .tab__item-skin a:hover {
    color: white;
    border-bottom: 4px solid white;
  }

  .next-btn {
    border-color: white;
    background-color: white;
    color: $color-aqua-sidebar;

    i {
      color: $color-aqua-sidebar;
    }

    &:focus {
      box-shadow: 0 0 0 2px $color-nt-grey-900;
    }

    &:active,
    &:focus,
    &:hover:not([disabled]) {
      background-color: $color-aqua-900;
      color: white;

      i {
        color: white;
      }
    }
  }
}

.advisor-documents-container {
  margin: 0 24px;
  padding: 24px;
  background-color: #FAFAFA;
  width: calc(100% - 48px);
}

.report-setup {
  &__config {
    padding: 32px 8px;
    background: $color-nt-grey-025;

    .section-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    }

    .radio {
      margin: 16px 0;
    }

    .radiogroup__label {
      display: none;
    }
  }

  .data-entry-header__title {
    .fontweight-medium {
      font-weight: 300 !important;
    }
  }

  .data-entry-side-drawer__form {
    width: 450px;

    .accordion {
      &__item {
        border: none;

        .accordion__button {
          cursor: pointer;

          .fontweight-normal {
            font-weight: 500 !important;
          }
        }

        .accordion__button:hover,
        .accordion__button:focus {
          background-color: unset;
          box-shadow: none;
        }
      }
    }

    .client-documents-accordion,
    .advice-library-accordion {
      border: none;
      margin-left: 32px;
      margin-top: 12px;

      &__items {
        list-style: none;
      }

      &__item {
        &__checkbox {
          padding: 6px;
          height: auto;

          .checkbox__label {
            padding-left: 28px;
            line-height: 20px;

            &::before {
              top: 4px;
            }
          }
        }
      }
    }

    .reporting-drawer__share-point-link {
      font-size: 12px;
      padding-left: 34px;
      margin-top: -6px;
      margin-bottom: 16px;
    }

    .reporting-drawer__no-docs-content {
      padding-top: 352px;
      padding-left: 102px;
      &__title{
        font-size: 24px;
        font-weight: 300;
      }
      &__message1{
        margin-top: -7px;
        padding-left: 19px;
        font-size: 13px;
      }
      &__message2{
        padding-left: 12px;
        font-size: 13px;
      }
      &__message3{
        padding-left: 68px;
        font-size: 13px;
      }
      &__share-point-link{
        font-size: 10px;
        padding-left: 117px;
        padding-top: 4px;
      }
    }

    .reporting-drawer__unable-to-load-documents{
      padding-top: 367px;
      padding-left: 104px;

      &__title {
        font-size: 24px;
        line-height: 30px;
        font-weight: 300;
        padding-left: 15px;
      }

      &__message1 {
        padding-left: 30px;
        font-size: 13px;
        margin-top: -10px;
      }
      &__message2 {
        padding-left: -4px;
        font-size: 13px;
      }
      &__message3 {
        padding-left: 10px;
        font-size: 13px;
      }

      &__message4 {
        padding-left: 53px;
        font-size: 13px;
      }

      &__message5 {
        padding-left: 37px;
        font-size: 13px;
      }

      &__refresh-button {
        font-size: 10px;
        padding-left: 154px;
        padding-top: 0px;
      }
    }

    .reporting-drawer__no-folder-exists{
      padding-top: 366px;
      padding-left: 99px;

      &__title1 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 300;
        padding-left: 0px;
      }

      &__title2 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 300;
        padding-left: 91px;
      }

      &__message1 {
        padding-left: 0px;
        font-size: 13px;
        margin-top: -10px;
      }
      &__message2 {
        padding-left: 19px;
        font-size: 13px;
      }
      &__message3 {
        padding-left: 30px;
        font-size: 13px;
      }

      &__message4 {
        padding-left: 59px;
        font-size: 13px;
      }

      &__message5 {
        padding-left: 41px;
        font-size: 13px;
      }

      &__refresh-button {
        font-size: 10px;
        padding-left: 84px;
        padding-top: 0px;
      }
    }

  }

  .advice-library-accordion {
    &__items {
      margin-bottom: 24px;
    }

    &__item {
      &__checkbox {
        margin-left: 20px;
      }
    }
  }

  .client-documents-accordion {
    &__items {
      margin-bottom: 12px;
    }

    &__item {
      &__checkbox {
        margin-left: 30px;
      }
    }
  }

  &__content {
    padding: 32px 8px;

    &__title {
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
    }

    .page-section-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
    }

    .page-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;

      .page-item-checkbox {
        margin: 0;
      }
    }
  }

  .document-summary-item {
    display: flex;
    justify-content: space-between;
    align-items: start;
    line-height: 40px;
    padding: 6px 0;
    border-bottom: 1px solid $color-nt-grey-100;

    &__left-content {
      display: flex;
      justify-content: start;
      align-items: start;
      padding: 6px;
      width: calc(100% - 32px);
    }

    &__name {
      line-height: 20px;
    }
  }
}

.preview-and-send {
  .loading-generate-report {
    height: calc(100vh - 200px);
    font-weight: 300;
  }

  .react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important;
  }

}

.disclaimer-page {
  zoom: 1.2;

  display: flex;
  align-items: flex-start;
  gap: 23px;

  .column {
    flex: 33.33%;
    padding-left: 32px;
    padding-right: 32px;
  }

  .pointer {
    margin-left: 15px;
  }

  .paragraph {
    color: $color-nt-grey-900;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
  }

  &__table_nested_grid {
    grid-column: 1/4;
  }

  .table_grid {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
  }
  .table_header {
    &__row {
      display: flex;
      height: 40px;
      padding: 0 8px;
      align-items: center;
      align-self: stretch;
      border-top: 1px solid $color-nt-grey-900;
      border-bottom: 1px solid $color-nt-grey-900;
      background: #FFF;

      color: $color-nt-grey-900;
      font-feature-settings: 'clig' off, 'liga' off;

      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 138.462% */
    }
    &__item1 {
      grid-column: 1/3;
    }
  }

  .table_row {
    display: flex;
    height: 40px;
    padding: 0 8px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-bottom: 1px solid $color-nt-grey-200;
    background: #FFF;

    color: $color-nt-grey-900;
    font-feature-settings: 'clig' off, 'liga' off;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px; /* 125% */
  }
  .word_wrap {
    white-space: pre-wrap;
  }
}
